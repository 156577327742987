import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/workspace/inbox-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "inbox-docz"
    }}>{`inbox-docz`}</h1>
    <p>{`Inbox Health's documentation website (and future Style Guide?).`}</p>
    <p>{`Built on top of Gatsby JS and Docz (a Gatsby theme for code documentation and help systems), this repository holds the code, content, and deployment scripting to build, upload and deploy the Inbox Health documentation available at docs.inboxhealth.com.`}</p>
    <h2 {...{
      "id": "running-locally"
    }}>{`Running locally`}</h2>
    <pre><code parentName="pre" {...{}}>{`yarn
yarn start
`}</code></pre>
    <h2 {...{
      "id": "deploying-to-cloudfront-docsinboxhealthcom"
    }}>{`Deploying to CloudFront (docs.inboxhealth.com)`}</h2>
    <p>{`First configure you AWS CLI with appropriate AWS keys then run:`}</p>
    <pre><code parentName="pre" {...{}}>{`yarn deploy
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      